
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";

export default defineComponent({
  name: "CreateAccount",
  created() {
    if (localStorage.getItem("userData")) {
      this.$router.replace("/");
      return;
    }
    this.$store.dispatch("forceReady", null, { root: true });
  },
  watch: {
    error(value) {
      console.error(value);
    },
    userLogin(value) {
      if (value) {
        this.$router.push("/");
      }
    },
    loadingStatus(value) {
      const loading = ElLoading.service({
        lock: true,
        text: "Login..",
        background: "#ffffff90",
      });
      if (!value) {
        loading.close();
      }
    },
    email(value) {
      this.checkingEmailStatus(value);
    },
  },
  computed: {
    userLogin(): any {
      return this.$store.getters.getLoginStatus;
    },
  },
  data() {
    return {
      email: "" as any,
      emailWarningText: "",
      validateEmail: false,
    };
  },
  mounted() {
    if (this.userLogin) {
      this.$router.push("/");
    }
    this.setLangOnInit();

    if (localStorage.getItem("loginEmail")) {
      this.email = localStorage.getItem("loginEmail") || "";
    }
  },
  methods: {
    linkToSignup() {
      this.$store.dispatch("auth/clearSignupFormDetails", null, {
        root: true,
      });
      this.$router.push({ name: "Signup" });
    },
    setLangOnInit() {
      const lang = this.$router.currentRoute.value.meta.lang || "en";
      if (lang && lang == "TH") {
        (this as any).$i18n.setLocale("th");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      } else {
        (this as any).$i18n.setLocale("en");
        localStorage.setItem("lang", (lang as string).toUpperCase());
      }
    },
    checkingEmailStatus(email: string) {
      const emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (this.email.length > 5 && emailValid) {
        this.validateEmail = true;
        // this.emailWarningText = "";
      } else if (this.email.length > 5 && !emailValid) {
        this.validateEmail = false;
        // this.emailWarningText = "This is not a valid email";
      } else {
        this.validateEmail = false;
        // this.emailWarningText = "";
      }
    },
    handleClickAuthProvider(provider: string) {
      this.signinByProvider(provider);
    },
    signinByProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
      };
      this.$store.dispatch("auth/login", authData, { root: true });
    },
    loginWithGoogle() {
      this.$store.dispatch("userLoginWidthGoogle", true);
    },
    async login() {
      if (this.validateEmail) {
        await this.$store.dispatch(
          "auth/setLoginEmail",
          { email: this.email },
          { root: true }
        );
        const valid = await this.$store.dispatch(
          "auth/requestLoginWeb",
          { email: this.email },
          { root: true }
        );
        if (valid) {
          this.$router.push({
            name: "Pincode",
            query: {
              email: this.email,
              flow: "login",
            },
          });
        } else {
          if (this.$store.getters["auth/error"]) {
            this.emailWarningText = this.$store.getters["auth/error"];
          }
        }
      }
    },
  },
});
